<template>
    <VueDatePicker
        ref="datePicker"
        :placeholder="placeholder || currentConfig.typing"
        :text-input="{ format: currentConfig.typing, enterSubmit: true }"
        :teleport="true"
        :format="currentConfig.typing"
        utc="preserve"
        v-bind="{ ...currentConfig?.props }"
        :min-date="dateLow"
        :max-date="dateHigh"
        :start-date="dateStart"
        :model-value="date"
        @text-submit="onTextSubmit"
        @text-input="onTextInput"
        @cleared="onChange"
        v-on="{ ...currentConfig?.actions }"
    >
        <!-- empty slots for action-preview and action-buttons are to remove buttons from datepicker. https://vue3datepicker.com/slots/content/#action-buttons -->
        <template #action-preview><div /></template>
        <template #action-buttons><div /></template>
    </VueDatePicker>
</template>

<script setup>
import { Mask } from 'maska'
import moment from 'moment'
import { useStore } from 'vuex'
import Utils from '@serv/Utils'
import VueDatePicker from '@vuepic/vue-datepicker'
import { computed, nextTick, ref } from 'vue'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
    dateFormat: {
        type: String,
        default: 'yyyy-mm-dd',
        // 'date' is an option just to have backward compatibility. Means same as 'yyyy-mm-dd'.
        validator: (v) => ['date', 'yyyy-mm-dd', 'yyyy-mm', 'yyyy'].includes(v)
    },
    placeholder: {
        type: String,
        default: ''
    },
    rangeLow: { type: [Number, Date], default: 0 },
    rangeHigh: { type: [Number, Date], default: 0 }
})

// update model value and emit to parent
const datePicker = ref()
const date = ref()

const onChange = (value) => {
    if (value) {
        /*
        By default, vue-datepicker works with 'select' and 'cancel' buttons. Buttons removed to be consistent with the existing DatePicker, which is working without control buttons.
        To control model change, we are using @dateUpdate and @updateMonthYear. These events emit values earlier than overwrite the internal modelValue.
        nextTick() is to wait for the next event loop cycle and update the modelValue on the next iteration of the event loop.
         */
        nextTick().then(() => {
            date.value = value
            datePicker.value.selectDate()

            let dateMoment = moment(value)
            if (dateMoment.isValid()) {
                emits('update:modelValue', dateMoment.format(currentConfig.value.format))
            }
        })
    } else {
        emits('update:modelValue', undefined)
    }
}

// month year DatePicker.
const monthAndYear = ref({ year: undefined, month: undefined })

const onChangeMonthYear = (value) => {
    /**
    vue-datepicker provides only a method to update AND year AND month, and it calls it when user change year by arrows.
    Skip update model when the year changes in a month picker to prevent model change and closing calendar.
    */
    if (
        props.dateFormat === 'yyyy-mm' &&
        monthAndYear.value?.year &&
        value?.year !== monthAndYear.value?.year
    ) {
        monthAndYear.value = value

        return
    }

    monthAndYear.value = value
    onChange(value)
}

// configuration map for different picker types
const store = useStore()
const user = computed(() => store.getters.user)

const locale = computed(() => {
    if (user.value && ['au', 'gb', 'us'].includes(user.value.countryIso)) {
        return `en-${user.value.countryIso.toUpperCase()}`
    }

    return navigator ? navigator.language : `en-GB`
})

const isUs = computed(() => {
    const event = new Date(Date.UTC(2021, 0, 31)) // 31 Jan 2021
    const dateFormat = event.toLocaleDateString(locale.value)

    return !dateFormat.startsWith('31')
})

const datePickerConfig = computed(() => {
    return {
        'yyyy-mm-dd': {
            typing: isUs.value ? 'MM/dd/yyyy' : 'dd/MM/yyyy',
            mask: '##/##/####',
            format: Utils.serialisedDateFormat,
            props: {
                enableTimePicker: false
            },
            actions: {
                dateUpdate: onChange
            }
        },
        'yyyy-mm': {
            typing: 'MM/yyyy',
            mask: '##/####',
            format: 'yyyy-mm',
            props: {
                monthPicker: true
            },
            actions: {
                updateMonthYear: onChangeMonthYear
            }
        },
        'yyyy': {
            typing: 'yyyy',
            mask: '####',
            format: 'yyyy',
            props: {
                yearPicker: true
            },
            actions: {
                updateMonthYear: onChangeMonthYear
            }
        }
    }
})

const currentConfig = computed(() => {
    return datePickerConfig.value[props.dateFormat] || datePickerConfig.value['yyyy-mm-dd']
})

// Low High and start dates
const dateLow = computed(() => {
    if (props.rangeLow) {
        if (typeof props.rangeLow == 'number') {
            return new Date(moment()
                .subtract(props.rangeLow, 'years')
                .toISOString())
        }

        if (moment(props.rangeLow).isValid()) {
            return props.rangeLow
        }
    }

    return null
})

const dateHigh = computed(() => {
    if (props.rangeHigh) {
        if (typeof props.rangeHigh == 'number') {
            return new Date(moment()
                .subtract(props.rangeHigh, 'years')
                .toISOString()
            )
        }

        if (moment(props.rangeHigh).isValid()) {
            return props.rangeHigh
        }
    }

    return null
})

const dateStart = computed(() => {
    const now = moment()
    if (dateLow.value && dateHigh.value) {
        if (now.isBetween(dateLow.value, dateHigh.value, undefined, [])) {
            return now.toDate()
        }

        return dateHigh.value
    }

    return now.toDate()
})

// action on type date manually in input
const onTextSubmit = () => {
    onChange(date.value)
}

const onTextInput = (event) => {
    const mask = new Mask({ mask: currentConfig.value.mask })

    if (mask.completed(event.target.value)) {
        const selectedDate = moment(mask.masked(event.target.value), currentConfig.value.typing.toUpperCase()).toDate()
        if (moment(selectedDate).isValid()) {
            date.value = selectedDate
            datePicker.value.parseModel(selectedDate)
            datePicker.value.updateInternalModelValue(
                selectedDate
            )
        }
    }
}
</script>
