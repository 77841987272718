// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { createVuetify } from 'vuetify'
import { VTreeview } from 'vuetify/labs/VTreeview'

export default createVuetify({
    components: {
        VTreeview
    },
    defaults: {
        global: {
            ripple: false,
            elevation: '0'
        }
    },
    display: {
        thresholds: {
            sm: 0,
            md: 768,
            lg: 1024,
            xl: 1920
        }
    },
    theme: {
        defaultTheme: 'custom',
        themes: {
            custom: {
                dark: false,
                colors: {
                    primary: '#0065e3',
                    'primary-dark': '#1b365d',
                    'grey-05': '#79829b',
                    'grey-06': '#5c637a'
                },
                variables: {
                    'primary-filter':
                        'invert(26%) sepia(11%) saturate(6925%) hue-rotate(192deg) brightness(98%) contrast(98%)',
                    'secondary-filter':
                        'invert(26%) sepia(11%) saturate(6925%) hue-rotate(192deg) brightness(98%) contrast(98%)'
                }
            }
        }
    },
    styles: {
        configFile: 'src/styles/base/_breakpoints.scss'
    },
    icons: {
        iconfont: 'mdi'
    }
})
