import _ from 'lodash'
import Auth0 from '@serv/Auth/Auth0'
import axios from 'axios'
import Config from '@serv/Config'
import ConfigManager from '@config/ConfigManager'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment'
import Redirect from '@serv/Redirect'
import RequestMock from '@serv/RequestMock'
import RequestMocker from '@serv/RequestMocker'
import Storage from '@serv/Storage'
import Utils from '@serv/Utils'

/**
 * Generic success and error functionality for all requests in the app.
 */
class Request {
    constructor() {
        this.errorCallbackMap = {}
        this.isLoggingVerbose = false
        this.requestMocker = undefined // if set, requests can be mocked

        this.addInterceptorForSpoofing()
        this.addInterceptorForServiceUnavailable()
        this.addInterceptorForUnauthenticated()

        // For updating DWH directly
        this.dwhLastUpdateMoment = undefined
    }

    addInterceptorForUnauthenticated() {
        axios.interceptors.response.use(null, error => {
            if (error.response?.status == 401) {
                Logging.warn('Unauthenticated request, reloading page')
                Storage.clear()

                if (Storage.get('auth_source') == 'auth0') {
                    Auth0.reset()
                    Auth0.revokeToken(Config.country)
                }

                window.location.reload()
            }

            return Promise.reject(error)
        })
    }

    addInterceptorForSpoofing() {
        const _this = this
        const whitelistedPostUrls = ['chat/v1/open/', 'patient-journeys/report/']

        axios.interceptors.request.use(request => {
            if (
                Storage.get('spoof') &&
                request.method != 'get' &&
                !request.url.startsWith('reports/v') &&
                whitelistedPostUrls.findIndex(url => request.url.includes(url)) == -1
            ) {
                // Error if we are spoofing, and we make a non-GET request that is NOT:
                // - to the reports endpoint
                const error = `Ignoring ${request.method.toUpperCase()} request to ${request.url} when spoofing`
                throw new Error(error)
            }
            if (_this.isLoggingVerbose) {
                // Log method, url, and payload if any
                Logging.log(
                    `Request: ${request.method.toUpperCase()} ${request.url} ${
                        request.data ? JSON.stringify(request.data, null, 2) : ''
                    }`
                )
            }

            return request
        })
    }

    addInterceptorForServiceUnavailable() {
        const _this = this
        axios.interceptors.response.use(response => {
            if (_this.isLoggingVerbose) {
                // Log status code and body if any
                Logging.log(
                    `Response: ${response.status} ${
                        !_.isEmpty(response.data) ? JSON.stringify(response.data, null, 2) : ''
                    }`
                )
            }
            // Handle response code 503 (service unavailable)
            if (response.status == 503 && ((response.config || {}).url || '').includes('dash/v')) {
                Redirect.gotoName('ServiceUnavailable')
            }

            return response
        })
        // Required to handle 503 errors during auth
        axios.interceptors.response.use(null, error => {
            if (error.message == 'Network Error' && error.config.url.includes('oauth2')) {
                Redirect.gotoName('ServiceUnavailable')
            }

            return Promise.reject(error)
        })
    }

    /**
     * Get custom headers to be set on each request.
     */
    getHeaders() {
        return {
            'Accept-Language': Locale.getActiveLocale()
        }
    }

    /**
     * Set callbacks for error responses
     * @param {Number}
     * @param {Function}
     */
    setErrorCallback(errorCode, callbackFn) {
        Logging.log('Setting callback for error code:', errorCode)
        this.errorCallbackMap[errorCode] = callbackFn
    }

    /**
     * Remove callback for error code
     * @param {Number}
     */
    removeErrorCallback(errorCode) {
        if (this.errorCallbackMap.hasOwnProperty(errorCode)) {
            delete this.errorCallbackMap[errorCode]
        }
    }

    // Are we mocking the specified request?
    isMockingRequestForUrl(url) {
        if (this.requestMocker) {
            return this.requestMocker
        }

        return !!(this.requestMocker || {})[url]
    }

    // Are we mocking the specified request?
    mockResponseForUrl(verb, url, payload, config) {
        if (this.requestMocker) {
            const fnName = this.requestMocker.verbUrlToResponseFn[verb][url]
            if (fnName) {
                // Returns false if we should not mock, otherwise a mock response object
                return this.requestMocker[fnName](url, payload, config)
            }
        }

        // Do not mock
        return false
    }

    /**
     * Make a series of param replacements within a URL.
     * e.g. if URL contains '{personaId}' and params defines personaId, make the replacement.
     */
    replaceUrlParams(url, params) {
        Object.keys(params || {}).forEach(key => {
            url = url.replace(`{${key}}`, params[key])
        })

        return url
    }

    // Wrapper for all GET requests within the app.
    get(url, config) {
        config = config || {}
        const mockResponse = this.mockResponseForUrl(this.Verb.get, url, null, config)
        if (mockResponse !== false) {
            return mockResponse
        }
        url = this.replaceUrlParams(url, config.params)

        return this._genericRequest(
            axios.get(url, {
                headers: this.getHeaders()
            })
        )
    }

    // Wrapper for all DELETE requests within the app.
    delete(url, config) {
        config = config || {}
        const mockResponse = this.mockResponseForUrl(this.Verb.delete, url, null, config)
        if (mockResponse !== false) {
            return mockResponse
        }
        url = this.replaceUrlParams(url, config.params)
        if (url.endsWith('/') || url.includes('?')) {
            return this._genericRequest(axios.delete(url, { headers: this.getHeaders() }))
        }
        Logging.error(`Request will not make DELETE to URL without trailing slash: ${url}`)
    }

    /**
     * Wrapper for all POST requests within the app.
     * url should NOT include the baseUrl.
     * credentials parameter is required for Auth.js
     */
    // post(url, data = null, credentials = null, baseUrl = '') {
    post(url, payload, config) {
        config = config || {}
        const mockResponse = this.mockResponseForUrl(this.Verb.post, url, payload, config)
        if (mockResponse !== false) {
            return mockResponse
        }
        url = this.replaceUrlParams(url, config.params)
        const axiosConfig = config.credentials || { headers: { ...this.getHeaders(), ...(config.headers || {}) } }
        const baseUrl = config.baseUrl || ''
        if (url.endsWith('/') || url.includes('?')) {
            return this._genericRequest(axios.post(`${baseUrl}${url}`, payload, axiosConfig))
        }
        Logging.error(`Request will not make POST to URL without trailing slash: ${url}`)
    }

    /**
     * Wrapper for all PUT requests within the app.
     */
    put(url, payload, config) {
        config = config || {}
        const mockResponse = this.mockResponseForUrl(this.Verb.put, url, payload, config)
        if (mockResponse !== false) {
            return mockResponse
        }
        url = this.replaceUrlParams(url, config.params)
        if (url.endsWith('/') || url.includes('?')) {
            if (url.includes('{') && url.includes('}')) {
                Logging.error(`Request URL has unreplaced parameters: ${url}`)

                return
            }

            return this._genericRequest(
                axios.put(url, payload, {
                    headers: this.getHeaders()
                })
            )
        }
        Logging.error(`Request will not make PUT to URL without trailing slash: ${url}`)
    }

    /**
     * Wrapper for all PATCH requests within the app.
     * 06/01/21 Changed so that full URL (after base) should be provided.
     */
    patch(url, payload, config) {
        config = config || {}
        const mockResponse = this.mockResponseForUrl(this.Verb.patch, url, payload, config)
        if (mockResponse !== false) {
            return mockResponse
        }
        url = this.replaceUrlParams(url, config.params)
        if (url.endsWith('/') || url.includes('?')) {
            return this._genericRequest(
                axios.patch(url, payload, {
                    headers: this.getHeaders()
                })
            )
        }
        Logging.error(`Request will not make PATCH to URL without trailing slash: ${url}`)
    }

    /**
     * Wrapper for resolving multiple chained http requests
     * All promises must succeed to return response
     * @param {Array} promisesArray
     * @return {Promise}
     */
    all(promisesArray) {
        return this._genericRequest(axios.all(promisesArray.filter(promise => promise)))
    }

    /**
     * Generic success and error resolutions for all request-based promises
     */
    _genericRequest(request) {
        let response = request
            .then(resp => {
                // If Config specifies, then save response as JSON
                const configDev = ConfigManager.devConfig
                if (configDev && configDev.mockSetWrite) {
                    const filename = Utils.urlToMockJsonFilename(resp.config.url, true)
                    Utils.downloadTextFile(JSON.stringify(resp.data, null, 2), filename)
                }

                return resp
            })
            .catch(err => {
                if (!!err.response && this.errorCallbackMap.hasOwnProperty(err.response.status)) {
                    let errCode = err.response.status
                    Logging.warn('Running callback for error code:', errCode)
                    this.errorCallbackMap[errCode].call()
                }
                throw err
            })

        return response
    }

    /**
     * Return an object as a query params string. For example:
     * { foo: "moo", bar: 1 } => 'foo=moo&bar=1'
     * NOTE: Does not handle nested objects.
     * https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
     */
    getObjectAsQueryParams(obj) {
        return new URLSearchParams(obj).toString()
    }

    /**
     * Trigger an immediate update of the DWH.
     * The config specifies the patientJourneyId to update.
     * startTime: If not specified on the config, use the time of the last call to this function, or now minus 1 minute
     * endTime: Always use now
     */
    updateDwh(config) {
        const payload = config || {}
        const nowMoment = moment()
        payload.endTime = nowMoment.format(Utils.serialisedDateTimeFormat)
        if (!payload.startTime) {
            // Start time is latest of now-1m, or the last datetime when we called this function
            let startMoment = moment().subtract(1, 'minutes')
            if (this.dwhLastUpdateMoment && this.dwhLastUpdateMoment > startMoment) {
                startMoment = this.dwhLastUpdateMoment
            }
            payload.startTime = startMoment.format(Utils.serialisedDateTimeFormat)
        }
        this.dwhLastUpdateMoment = nowMoment

        const url = this.Stem.updateDwh
        this.post(url, payload)
            .then(() => {
                Logging.log(`POST updateDwh success: ${JSON.stringify(payload, null, 2)}`)
            })
            .catch(error => {
                Logging.log(`POST updateDwh error: ${error}`)
            })
    }

    /**
     * All Request verbs.
     */
    get Verb() {
        return {
            delete: 'delete',
            get: 'get',
            patch: 'patch',
            post: 'post',
            put: 'put'
        }
    }

    /**
     * All Request URL stems.
     */
    get Stem() {
        return {
            // GET ActivityLists by slugs
            activityLists: 'dash/v1/activitylists/?slugs={slugs}',
            // Admin: archive a patient
            adminArchivePatient: 'dash/v3/patients/{patientId}/archive/',
            // Admin: download content
            adminContentDownload: 'dash/v1/content/{slugVersionLocale}/',
            // Admin: delete patient results
            adminDeletePatientResults: 'dash/v3/patients/{patientId}/results/bulk-delete/',
            // Admin: search for clinicians
            adminSearchClinicians: 'dash/v1/teammembers/',
            // Admin: search for patients
            adminSearchPatients: '/patients/',
            // Admin: search for producerexecs
            adminSearchProducerExecs: 'dash/v3/producerexecs/',
            // Admin: search for providerexecs
            adminSearchProviderExecs: 'dash/v3/providerexecs/',
            // Admin: get user auth token
            adminUserAuthToken: 'dash/v3/generate-access-token/',
            // Upload an avatar
            avatar: 'dash/v1/upload-avatar/',
            // POST sendbird user, session token and channel status by patient journey id
            chatOpen: 'chat/v1/open/{patientJourneyId}/',
            // POST chat pane opened
            chatPaneOpened: 'chat/v1/channels/{channelUrl}/opened/',
            // Check a phone number (for validity, and isMobile)
            checkPhone: 'api/v1/check-phone/',
            // GET a list of content
            content: 'dash/v1/content/?locale={locale}',
            // POST return a list of content by { slug, version }
            contentBySlugVersion: '/cms/v2/filter-content/?versionedItemSlugs={slugs}',
            // GET custom survey result PDF
            customPdfDownload: '/export/v1/activity_result/{surveySlug}/{activityResultId}/pdf/',
            // POST a new exercise template
            exerciseTemplateCreate: 'dash/v1/content/',
            // PUT a new exercise template
            exerciseTemplateUpdate: 'dash/v1/content/{slugVersionLocale}/',
            // GET feature flags
            featureFlags: '/feature-flags/',
            // POST Goddard (v3) patient list (PJ list)
            goddardPatientList: '/patient-journeys/report/',
            // POST hospital number and DOB to get unauthenticated access
            hospitalNumberAuth: 'api/v1/get-patient-id/?{params}',
            // GET a list of journeys
            journeys: 'dash/v2/journeys/?locale={locale}',
            // GET a journey by id
            journey: 'dash/v2/journeys/{journeyId}/?locale={locale}',
            // GET content for a journey by ID
            journeyContent: 'dash/v1/content/?journey_id={journeyId}&locale={locale}',
            // GET all milestones
            milestones: 'dash/v1/milestones/',
            // OAuth, here for mock mapping only
            oauth: 'oauth2-provider/token',
            // GET user report
            overviewReports: 'dash/v2/{type}/{id}/reports/{slug}',
            // Unauthenticated route to GET full owner object by slug
            owner: 'api/v1/owners/{ownerSlug}/',
            // PATCH a patient
            patient: 'dash/v3/patients/{patientId}/',
            // Request a patient password reset
            patientPasswordReset: 'patients/{id}/reset-password/',
            // GET a list of patients, or POST (invite) a patient
            patients: 'dash/v3/patients/',
            // POST a patient terms page consent event
            patientTermsPageConsentEventCreate: 'dash/v3/patients/{patientId}/events/',
            // POST a patient terms page result
            patientTermsPageInfoResultCreate: 'dash/v3/patients/{patientId}/results/',
            // POST a new patient journey for a patient
            patientJourneyAdd: 'dash/v3/patients/{patientId}/patientjourneys/',
            // PUT/POST an ExerciseRoutine as a template
            patientExerciseRoutine: 'dash/v2/patients/exerciseroutines/',
            // GET/PUT a patient exercise routine modifier, for an activity
            patientExerciseRoutineModifier:
                'dash/v2/patientjourneys/{patientJourneyId}/exerciseroutines/{activitySlug}/',
            // GET a patient exercise routine modifier history, for an activity
            patientExerciseRoutineModifierHistory:
                'dash/v2/patientjourneys/{patientJourneyId}/exerciseroutines/{activitySlug}/history/',
            // POST a patient exercise routine modifier, for a activity
            patientExerciseRoutineModifiers: 'dash/v2/patientjourneys/{patientJourneyId}/exerciseroutines/',
            // PATCH or DELETE a patient journey
            patientJourney: 'dash/v3/patients/{patientId}/patientjourneys/{patientJourneyId}/',
            // GET a list of patient journeys
            patientJourneys: 'dash/v3/patientjourneys/',
            // GET a patient journey's exercise summary as PDF
            patientJourneysExerciseExport: '/patient-journeys/{patientJourneyId}/exercise-routine-export/',
            // PATCH a milestone
            patientJourneyMilestone: 'dash/v2/patientjourneys/{patientJourneyId}/milestones/{milestoneId}/',
            // GET patient journey milestone history
            patientJourneyMilestoneHistory:
                'dash/v2/patientjourneys/{patientJourneyId}/milestones/{milestoneId}/history/',
            // POST a new milestone
            patientJourneyMilestones: 'dash/v2/patientjourneys/{patientJourneyId}/milestones/',
            // GET a patient journey report
            patientJourneyReport:
                'dash/v2/patientjourneys/{patientJourneyId}/reports/{reportSlug}/?filter=includeInactive',
            // GET specfic patient's PatientJourneys
            patientPatientJourneys: '/patients/{patientId}/patient-journeys/?{params}',
            // GET patient history of communications etc for timeline
            patientTimestampedData: '/logging/persona-timeline/{personaId}?sort=-id&limit={limit}',
            // POST a patient NHS number (via backend) to PDS API for validation
            patientNhsNumberValidation: '/nhs/v1/pds-validation/',
            // POST self-registration get token
            patientNhsNumberToken: '/nhs/v1/token/',
            // POST a dash web survey result
            patientWebSurveyResultCreate: 'dash/v3/patients/{patientId}/results/',
            // PUT a dash web survey result
            patientWebSurveyResultUpdate: 'dash/v3/patients/{patientId}/results/{resultId}/',
            // GET a list of providers
            providers: 'dash/v1/providers/',
            // GET a provider by slug
            provider: 'dash/v1/providers/{providerSlug}/',
            // GET a report
            reports: 'reports/v1/{reportSlug}/',
            // GET a report for download
            reportsDownload: 'reports/v1/downloads/${reportSlug}/',
            // GET a report
            reportsFiltered: 'reports/v2/{reportSlug}/',
            // POST to resend email/SMS (depending on patient registration method)
            resendInvitation: 'dash/v3/patients/{patientId}/resend-invitation/',
            // GET ROM image
            rom: 'rom/v1/results/{activityResultId}/images/{contentSlug}/',
            // GET RTM summary PDF
            rtmSummaryPdf: '/patient-journeys/{patientJourneyId}/rtm-milestone-export/',
            // POST Check patient exists through specified patient fields when inviting patient
            searchPatients: 'persona/patients/?{params}',
            // GET for old-style patient self-registration
            selfInvite: 'register/v1/surgeons/{slugPrefix}/',
            // POST to self-register a patient from scratch
            selfRegister: 'register/v1/patients/',
            // POST create un-registered user (used in self-referral)
            selfCreateUnregisteredUser: 'api/v1/patients/create/un-registered/',
            // GET or POST to perform a patient registration completion (e.g. Pennine)
            selfRegistrationComplete: 'register/v1/patients-complete/',
            // PATCH a surgeons (for ProducerExecs)
            clinician: 'dash/v2/surgeons/{clinicianEmail}/',
            // GET a list of surgeons (for ProducerExecs)
            // Do not rename - ResourceService expects this!
            surgeons: 'dash/v2/surgeons/',
            // GET a list of teams
            teams: 'dash/v2/teams/',
            // GET a team by ID
            team: 'dash/v2/teams/{teamId}/',
            // DELETE a team member (or GET for admin)
            teamMember: 'dash/v1/teammembers/{personaId}/',
            // GET a list of team members
            teamMembers: 'dash/v1/teammembers/',
            // POST to update dwh, after Patient/PJ/PJM edits
            updateDwh: '/dash/v1/dwh/update-patientjourney/',
            // Change password
            userChangePassword: '/dash/v2/users/change-password/', // remove leading slash
            // Reset password
            userResetPassword: '/dash/v2/users/reset-password/', // remove leading slash
            // PATCH user
            user: 'dash/v2/users/{personaId}/',
            // Register or Login
            users: '/dash/v2/users/' // TODO remove leading slash. Note we compare against this when checking for 503
        }
    }
    get MobileAppStem() {
        return {
            user: 'prm/v1/users/',
            modifiers: 'prm/v3/patients/{patientId}/patientjourneys/{patientJourneyId}/modifiers/',
            patient: 'prm/v3/patients/{patientId}/',
            journeys: 'prm/v4/patients/{patientId}/patientjourneys/?platform={platform}',
            contentUnmapped: 'prm/v3/patients/{patientId}/unmapped-content/?appVersion={appVersion}&locale={locale}',
            contentSlugMapped: 'cms/v2/patients/{patientId}/filter-content/',
            results: 'prm/v3/patients/{patientId}/results/'
        }
    }
    get Error() {
        return {
            mfaEnforced: 'mfa-enforced'
        }
    }
}

/**
 * Export the real or the mock instance depending on env.
 */
let instance
if (ConfigManager.isMockingServer) {
    instance = new RequestMock()
    // This is horrible - find a better way!
    const base = new Request()
    instance.Verb = base.Verb
    instance.Stem = base.Stem
    instance.getObjectAsQueryParams = base.getObjectAsQueryParams
} else {
    instance = new Request()
    instance.requestMocker = new RequestMocker(instance)
}

export default instance
